import Vue from 'vue';
import router from '@/router/index';

export default {
  async getUsers(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/listUsers`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/user/listUsers';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      userName: query.username ?? '',
      fullName: query.name ?? '',
      merchantId: '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of users.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createUser(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/user/create';
    }

    const user = `${parameters.username}:${parameters.password}`;
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
        user: `${btoa(user)}`,
        'Content-Type': 'application/json',
      },
    };

    const body = {
      firstName: parameters.firstName,
      lastName: parameters.lastName,
      email: parameters.email,
      roles: parameters.role,
    };
    console.log(body);
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response, response.data);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating of user.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updateUser(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/user/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      firstName: parameters.firstName,
      lastName: parameters.lastName,
      email: parameters.email,
      roles: parameters.role,
      username: parameters.username,
      isEnabled: parameters.isEnabled,
    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating user.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async listUserRoles(username) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/listUserRoles`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/user/listUserRoles';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      username,

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of users.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  /**
   * Change/Update User Password
   */
  /**
   * Update Account User's Password
   * parameters: JSON Object containing:
   * oldPassword: string
   * newPassword: string
   * username: string
   */
  async updateAccountPassword(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/updatePassword`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'switch/user/updatePassword';
    }
    const user = `${parameters.username}:${parameters.oldPassword}:${parameters.newPassword}`;
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
        user: `${btoa(user)}`,
        'Content-Type': 'application/json',
      },
    };
    const body = {};
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        /*
        * Response.data - JSON object and contains:
        * message - string
        * statusCode - string; http status code
        */
        return response.data;
      } if (response && response.data && response.data.message) {
        /*
        * Response.data JSON object and contains:
        * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
        * status - int; http status code; optional response
        * statusCode - string; http status codel optional response
        * message - string; always
        * expired - boolean; optional response
        */
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating your password.' };
    } catch (error) {
      /**
      * Exception error
      */
      console.log(error);
      if (error && error.response && error.response.data) {
        console.log(error.response);
        if (error.response.data.message) {
          return { error: error.response.data.message };
        }
        return { error: error.response.data };
      }
      return { error: `Error: ${error}` };
    }
  },
  /**
   * Get list of Merchants
   */
  async getMerchants(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/merchant/list`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/merchant/list';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      code: query.code ?? '',
      name: query.name ?? '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of merchants.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createMerchant(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/merchant/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/merchant/create';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.merchantCode,
      name: parameters.merchantName,
      remarks: parameters.remarks,
      webhook: parameters.webhook,
      successUrl: parameters.successUrl,
      failUrl: parameters.failUrl,
      paymentMethods: JSON.stringify(parameters.paymentOptionsRate),
      merchantLogoUrl: parameters.merchantLogoUrl,
    };

    console.log(body);
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.data.merchant) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating merchant.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updateMerchant(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/merchant/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/merchant/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.merchantCode,
      name: parameters.merchantName,
      remarks: parameters.remarks,
      webhook: parameters.webhook,
      successUrl: parameters.successUrl,
      failUrl: parameters.failUrl,
      secret: parameters.secretKey,
      connectTimeout: parameters.connectionTimeout,
      readTimeout: parameters.readTimeout,
      isEnabled: parameters.isEnabled,
      paymentMethods: JSON.stringify(parameters.paymentOptionsRate),
      merchantLogoUrl: parameters.merchantLogoUrl,
      isWebhookEnabled: parameters.isWebhookEnabled,
    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating merchant.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getPaymentMethods(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/list`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/paymentMethod/list';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      code: query.code ?? '',
      name: query.name ?? '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of payment methods.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createPaymentMethod(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/paymentMethod/create';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.code,
      name: parameters.name,
      currency: parameters.currency === 'OTHERS' ? parameters.customCurrency : parameters.currency,
      endpointUrl: parameters.endpointUrl,
      tenant: parameters.tenant,
      walletSystem: parameters.walletSystem
    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.data.paymentMethod) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating payment method.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updatePaymentMethod(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/paymentMethod/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.code,
      name: parameters.name,
      currency: parameters.currency === 'OTHERS' ? parameters.customCurrency : parameters.currency,
      endpointUrl: parameters.endpointUrl,
      secret: parameters.secretKey,
      connectTimeout: parameters.connectionTimeout,
      readTimeout: parameters.readTimeout,
      isEnabled: parameters.isEnabled,
      tenant: parameters.tenant,
      walletSystem: parameters.walletSystem
    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating payment method.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getAuthorities(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/authority/search`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/authority/search';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      name: query.name ?? '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of authorities.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getAllAuthorities() {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/authority/list`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/authority/list';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: 0,
      pageSize: 0,
      name: '',
    };

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of authorities.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createAuthority(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/authority/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/authority/create';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      name: parameters.name,

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.data.authority) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating authority.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updateAuthority(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/authority/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/authority/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      name: parameters.name,
      newName: parameters.newName,

    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.data.authority) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating auhtority.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getRoles(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/role/search`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/role/search';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      name: query.name ?? '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of authorities.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getAllRoles() {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/role/listRoles`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/role/listRoles';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
    };

    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of authorities.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createRole(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/role/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/role/create';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      name: parameters.name,
      description: parameters.description,
      authList: parameters.authorities,

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.data.role) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating authority.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updateRole(parameters) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/role/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/role/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      name: parameters.name,
      description: parameters.description,
      authList: parameters.authorities,
    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.data.role) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error updating auhtority.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  getError(error) {
    if (error && error.response && error.response.data) {
      console.log(error.response);
      if (error.response.data.message) {
        return { error: `${error.response.data.message}` };
      } if (error.response.data.error) {
        return { error: `${error.response.data.error}` };
      }
      return { error: `${error.response.data}` };
    }
    return { error: `${error}` };
  },

  async getWalletSystems(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/walletsystem/list`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/walletsystem/list';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      code: query.code ?? '',
      name: query.name ?? '',
      pageNo: offset.toString(),
      pageSize: limit.toString()
    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of merchants.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getWalletSystemByCode(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/user/listUsers`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/user/listUsers';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      userName: query.username ?? '',
      fullName: query.name ?? '',
      merchantId: '',

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of users.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async createWalletSystem(parameters) {
    console.log('create wallet system');
    console.log(parameters);
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/walletsystem/create`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/walletsystem/create';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.code,
      displayName: parameters.displayName,
      baseUrl: parameters.baseUrl,
      balanceEndpoint: parameters.balanceEndpoint,
      isDataDriven: parameters.isDataDriven,
      debitConsumerTopic: parameters.debitConsumerTopic,
      debitProducerTopic: parameters.debitProducerTopic,
      debitEndpoint: parameters.debitEndpoint,
      accountsEndpoint: parameters.accountsEndpoint,
      transactionEndpoint: parameters.transactionEndpoint,
      refundEndpoint: parameters.refundEndpoint

    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.data.walletSystem) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating Wallet System.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async updateWalletSystem(parameters) {
    console.log('create wallet system');
    console.log(parameters);
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/walletsystem/update`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/walletsystem/update';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };

    const body = {
      code: parameters.code,
      displayName: parameters.displayName,
      baseUrl: parameters.baseUrl,
      balanceEndpoint: parameters.balanceEndpoint,
      isDataDriven: parameters.isDataDriven,
      debitConsumerTopic: parameters.debitConsumerTopic,
      debitProducerTopic: parameters.debitProducerTopic,
      debitEndpoint: parameters.debitEndpoint,
      accountsEndpoint: parameters.accountsEndpoint,
      transactionEndpoint: parameters.transactionEndpoint,
      refundEndpoint: parameters.refundEndpoint

    };
    try {
      const response = await Vue.axios.put(url, body, config);
      console.log(response);
      if (response && response.data && response.data.walletSystem) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error creating Wallet System.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
  async getTransactions(offset, limit, query) {
    /** Use when CORS issue is resolved, directly communicate to server without proxy */
    let url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/transaction/list`;
    if (process.env.VUE_APP_IS_PROXY === 'true') {
      /** Use when CORS issue persists, and use proxy */
      url = 'paygateway/transaction/list';
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`,
      },
    };
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString()
    };
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } if (response && response.data && response.data.message) {
        return { error: response.data.message };
      }
      return { error: 'Sorry. Error getting list of merchants.' };
    } catch (error) {
      console.log(error);
      return this.getError(error);
    }
  },
};
