import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
// import vuetifyRouter from '@/demo/router/vuetify';
// import nestedRouter from '@/demo/router/nested';
// import componentsRouter from '@/demo/router/components';
// import authRouter from './modules/auth';
// import errorsRouter from './modules/errors';
// import permissionRouter from './modules/permission';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

export const constantRoutes = [
  {
    path: '/',
    alias: '/',
    name: 'dashboard',
    component: () => import('@/views/modules/Dashboard/index.vue'),
    meta: {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      roles: ['any']
    },
  },
  {
    path: '/merchants',
    alias: '/merchants',
    name: 'merchant-management',
    component: () => import('@/views/modules/Merchant/index.vue'),
    meta: {
      title: 'Merchant Management',
      icon: 'mdi-store',
      roles: ['AUTH_LIST_MERCHANT', 'AUTH_CREATE_MERCHANT', 'AUTH_UPDATE_MERCHANT', 'AUTH_ADMIN', 'AUTH_UPDATE_MERCHANT_SECRET', 'AUTH_READ_MERCHANT_SECRET']
    },
  },
  {
    path: '/payment-options',
    name: 'payment-options',
    component: () => import('@/views/modules/PaymentOptions/index.vue'),
    meta: {
      title: 'Payment Options',
      icon: 'mdi-cash-multiple',
      roles: ['AUTH_LIST_PAYMENTMETHOD', 'AUTH_CREATE_PAYMENTMETHOD', 'AUTH_UPDATE_PAYMENTMETHOD', 'AUTH_ADMIN', 'AUTH_UPDATE_PAYMENTMETHOD_SECRET', 'AUTH_READ_PAYMENTMETHOD_SECRET']
    },
  },
  {
    path: '/users',
    name: 'user-management',
    component: () => import('@/views/modules/User/index.vue'),
    meta: {
      title: 'User Management',
      icon: 'mdi-account-multiple',
      roles: ['AUTH_LIST_USER', 'AUTH_CREATE_USER', 'AUTH_UPDATE_USER', 'AUTH_ADMIN']
    },
  },
  {
    path: '/transactions',
    name: 'transaction-management',
    component: () => import('@/views/modules/Transaction/index.vue'),
    meta: {
      title: 'Transaction Management',
      icon: 'mdi-account-cash-outline',
      roles: ['AUTH_LIST_TRANSACTION', 'AUTH_CREATE_TRANSACTION', 'AUTH_UPDATE_TRANSACTION', 'AUTH_ADMIN']
    },
  },
  {
    path: '/service-fee',
    name: 'service-fee',
    component: () => import('@/views/modules/ServiceFee/index.vue'),
    meta: {
      title: 'Service Fee Management',
      icon: 'mdi-cash',
      roles: ['AUTH_LIST_SERVICEFEE', 'AUTH_CREATE_SERVICEFEE', 'AUTH_UPDATE_SERVICEFEE', 'AUTH_ADMIN']
    },
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/modules/Role/index.vue'),
    meta: {
      title: 'Role Management',
      icon: 'mdi-account-key',
      roles: ['AUTH_LIST_ROLE', 'AUTH_CREATE_ROLE', 'AUTH_UPDATE_ROLE', 'AUTH_ADMIN']
    },
  },
  {
    path: '/authority',
    name: 'authority',
    component: () => import('@/views/modules/Authority/index.vue'),
    meta: {
      title: 'Authority Management',
      icon: 'mdi-account-key',
      roles: ['AUTH_LIST_AUTHORITY', 'AUTH_CREATE_AUTHORITY', 'AUTH_UPDATE_AUTHORITY', 'AUTH_ADMIN']
    },
  },
  {
    path: '/WalletSystem',
    name: 'WalletSystem',
    component: () => import('@/views/modules/WalletSystem/index.vue'),
    meta: {
      title: 'Wallet System Management',
      icon: 'mdi-account-key',
      roles: ['AUTH_LIST_WALLET_SYSTEM', 'AUTH_CREATE_WALLET_SYSTEM', 'AUTH_UPDATE_WALLET_SYSTEM', 'AUTH_ADMIN']
    },
  },
  {
    path: '/401',
    alias: '/401',
    name: '401',
    component: () => import('@/views/errors/401.vue'),
    hidden: true,
  },
  // ...authRouter,
];

export default new Router({
  mode: 'history', // gh-pages
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

export const asyncRoutes = [
  /** When your routing table is too long, you can split it into small modules */
  // permissionRouter,
  // vuetifyRouter,
  // ...componentsRouter,
  // nestedRouter,
  // errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true },
];
